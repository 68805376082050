.work-order-report {
  font-family: 'Arial', sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.report-title {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.work-order-selection {
  margin-bottom: 1em;
}

.stage-section {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.stage-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 0.5em;
}

.scrapped-serial {
  color: #d84a3d; /* Or any color you prefer for scrapped items */
}

.good-serial {
  color: #3dd842; 
}