
.container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.item-list,
.sections {
  width: 45%;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding: 1rem;
  background: white;
  margin-bottom: 1rem;
}

.item-list h2,
.sections h2 {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 1rem;
}

.section {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 20px;
  margin-right: 20px;
  cursor: move;
}

.item {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-section,
.publish-section {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.add-section:hover,
.publish-section:hover {
  background-color: #f5f5f5;
}

.item button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

body{
  background-color: #F8F8F8;
}

.item button .react-icon {
  margin-right: 5px; /* Space between icon and text */
}

.item input[type="checkbox"] {
  /* Custom styling for checkbox */
  margin-right: 10px; /* Space next to checkbox */
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Adjusts spacing between elements */
  padding-bottom: 10px; /* Adds padding below the header for separation */
}

/* Additional styles for headers within sections */
.section-header button {
  margin: 0 5px; /* Adjust spacing between buttons */
  white-space: nowrap; /* Prevents text inside buttons from wrapping */
}

/* Improve the visibility and spacing for headers */
.section-header h2 {
  font-size: 1.25rem; /* Make it slightly larger */
  margin-right: 10px; /* Ensure spacing between the title and buttons */
}

/* Correcting alignment issues */
.add-product-button {
  margin-top: -2px; /* Adjust as necessary to align with input */
}

.section-items {
  margin-top: 1rem;
}

.remove-item {
  margin-left: 0.5rem;
}

.start-placeholder,
.end-placeholder {
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  text-align: center;
  margin-bottom: 5px;
  border-radius: 4px;
}

/* General button styling */
.button {
  padding: 8px 15px; 
  margin: 0.1rem;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50; /* Green background for primary actions */
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px; /* Space between icon and text */
  transition: background-color 0.3s ease;
  height: 40px; /* Adjust height to align with input fields */
  line-height: 40px; /* This should match height for vertical alignment */
}

.button-delete {
  background-color: #f44336; /* Red background for delete actions */
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.button:hover {
  background-color: #45a049; /* Darker shade on hover */
}

.button.secondary {
  background-color: #f44336; /* Red background for secondary actions */
}

.button.secondary:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}

.filter-add-container {
  display: flex; /* Align inline elements using flexbox */
  align-items: center; /* Align vertically */
  justify-content: center; /* Center horizontally, if needed */
}

.filter-input {
  flex-grow: 1;
  margin-right: 8px; /* Adjust spacing between input and button */
  padding: 8px 12px; /* Adjust padding to match button's height */
  font-size: 1rem; /* Optional: adjust font size */
  height: auto; /* Adjust height as needed */
  /* Add additional styles as needed */
}

.add-process-button {
  padding: 0.5rem; /* Padding to make the text inside the button align with the input height */
  margin-left: 10px; /* Space between the input and button */
  margin-bottom: 0,5em;
  border-radius: 4px;
  height: 40px; /* Explicit height to match the input */
  border-radius: 4px; /* Optional: match the border-radius with the input */
  font-size: 1rem; /* Match font size of the input, if needed */
  box-sizing: border-box;
}


/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 110%;
  margin: 0.3em;
  display: inline;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5em;
}

/* Specific styles for checkbox alignment */
.checkbox-inline input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 0.5rem; /* Adjust space between checkbox and label */
}

.item-icon {
  font-size: 1.2em;
}

/* Adjusts the size and positioning of icons within items */
.item .item-icon {
  vertical-align: middle; /* Aligns icons vertically with adjacent text */
}
/* Align items in the form header */
.form-header {
  display: flex;
  align-items: center; /* This will vertically align the elements */
  gap: 10px;
}

/* Use flexbox for the product header to manage spacing */
/* Product header styles */
.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem; /* Adds space below the product header */
}

input[type='checkbox'] {
  cursor: pointer; /* Changes cursor to pointer to indicate clickable area */
}

.edit-product-button,
.delete-product-button {
  font-size: 0.85rem; /* Adjusts font size for better fit and alignment */
  padding: 5px 10px; /* Adjusts padding for a more compact look */
}
input[type="text"],
input[type="password"],
select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 0.5rem; /* Adjust margin to prevent cutting off the outline */
  box-sizing: border-box; /* Ensures padding is included in width */
  outline-offset: 2px; /* Adds space between the border and the outline */
}

.checkboxlabel {
  display: inline-flex;      /* Keep checkbox & text on same line */
  align-items: center;       /* Vertically center items */
}

.checkbox-label input[type="checkbox"] {
  margin-right: 8px;         /* Spacing before text */
}

input[type="text"]:focus,
input[type="password"]:focus,
select:focus {
  outline: 2px solid #4D90FE; /* WCAG compliant focus outline color */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #4caf50;
  color: white;
}

.content.sidebar-active {
  margin-left: 150px; /* Adjust this value based on your sidebar's width */
  transition: margin-left 0.5s ease-out;
}

.content.sidebar-inactive {
  margin-left: 0; /* Adjust this depending on your original style */
}

.content-area {
  overflow-x: auto;
}