.App {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  font-family: sans-serif;
  text-align: center;
}

.content {
  flex-grow: 1;
  overflow-x: auto;
}

@media screen and (min-width: 768px) {
  .content {
    /* desktop styles here */
    margin-left: 150px; /* adjust as needed based on the actual width of your sidebar */
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
}