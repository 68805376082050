/* WorkOrderOverview.css */
.work-order-overview {
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.work-order-overview .ant-table {
  min-width: 100%;
}

.work-order-overview .ant-table-container {
  overflow-x: auto;
}

.work-order-overview .ant-table-thead > tr > th {
  background-color: #4CAF50; /* Adjust the color to match your green button color */
  color: white; /* Set the text color to white for better readability on green background */
  font-weight: 600; /* Keep it bold as per your design */
}

.ant-table-column-sorter-up svg,
.ant-table-column-sorter-down svg,
.ant-table-filter-trigger svg {
  fill: white; /* Changes the color of the svg icon */
}

/* Overriding styles for the filter dropdown buttons */
.ant-table-filter-dropdown .ant-btn-primary {
  background-color: #4CAF50; /* Green background for primary actions */
  border-color: #4CAF50; /* Green border to match */
  color: white; /* White text color */
}

.ant-table-filter-dropdown .ant-btn-primary:hover {
  background-color: #45a049; /* Slightly darker green on hover */
  border-color: #45a049; /* Border color to match on hover */
}

.ant-table-filter-dropdown .ant-btn {
  border-radius: 4px; /* Match the border-radius of your other buttons */
  color: white; /* White text color for consistency */
}

.ant-table-filter-dropdown .ant-btn-default {
  background-color: #f44336; /* Red background for secondary actions like reset */
  border-color: #f44336; /* Red border to match */
  color: white; /* White text color */
}

.ant-table-filter-dropdown .ant-btn-default:hover {
  background-color: #d32f2f; /* Slightly darker red on hover */
  border-color: #d32f2f; /* Border color to match on hover */
}

/* Changing icon colors to white for visibility */
.ant-table-filter-dropdown .anticon {
  color: white; /* White icon color */
}

/* Optional: Remove the out-of-box Ant Design shadow from the buttons if needed */
.ant-table-filter-dropdown .ant-btn-primary, 
.ant-table-filter-dropdown .ant-btn-default {
  box-shadow: none;
}

.work-order-overview .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
}

.work-order-overview .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f5f5f5;
}

.work-order-overview .ant-table-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.work-order-overview .ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: flex-end;
}
