.Sidebar {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #4a4a4a;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.Sidebar.show {
  z-index: 1; 
  transform: translateX(0%);
  z-index: 100;
}

@media screen and (min-width: 768px) {
  .Sidebar {
    width: 150px;
    transform: translateX(0%);
  }
}

.Sidebar .scrollable-area {
  overflow-y: auto;
  height: calc(100% - 60px); /* Adjust this value as needed */
}

.Sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;  /* add this */
  display: flex;  /* add this */
  flex-direction: column;  /* add this */
  justify-content: space-between;  /* add this */
}

.Sidebar li {
  margin-bottom: 20px;
}

.Sidebar a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  position: relative;
  padding: 8px 16px;
  display: inline-block;
}

.Sidebar a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #61dafb;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.Sidebar a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.Sidebar button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 8px 16px;
  width: 100%;
  text-align: left;
  border-top: 1px solid #4a4a4a;
}

.Sidebar .UserInfo {
  color: #fff;
}

.Sidebar .UserInfo:after {
  content: '';
  display: block;
  width: 90%; /* adjust as needed */
  height: 1px;
  background: #fff;
  margin: 10px auto;
}