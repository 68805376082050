.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-modal-content {
  position: relative;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.form-modal-content h2 {
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: bold;
}

.form-modal-content label {
  display: block;
  margin: 0.5rem 0;
}

.form-modal-content .radio-label {
  display: inline-flex;
  margin: 0.5rem 0;
}

.form-modal-content input,
.form-modal-content select {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-modal-content .add-button, .edit-button, .show-children-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0.5rem 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.form-modal-content .add-button:disabled, .edit-button:disabled, .show-children-button:disabled, .button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.form-modal-content button:hover {
  background-color: #45a049;
}

.close-button {
  position: absolute;
  top: -1px;
  right: 3px;
  background-color: #f44336;
  color: white;
  width: 24px;
  height: 24px;
  font-size: 16px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.disabled-label,
.disabled-select {
  color: #888;
  cursor: not-allowed;
}

.toast-container {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.react-select__value-container {
  justify-content: flex-start !important;
}

.serial-list-container {
  max-height: 500px; /* Adjust based on your needs */
  overflow-y: auto; /* Enables vertical scrollbar if content overflows */
  border: 1px solid #ccc; /* Basic styling, customize as needed */
  padding: 10px;
  margin-top: 10px;
}

.serial-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.remove-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.remove-button:hover {
  background-color: #d32f2f;
}

.master-serial-info {
  max-height: 200px; /* Adjust based on preference */
  overflow-y: auto; /* Enables vertical scrolling */
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Optional, for styling */
  padding: 10px;
  border-radius: 5px; /* Optional, for styling */
}

.master-serial-info ul {
  padding: 0;
  margin: 0;
  list-style-type: none; /* Removes default list bullet points */
}

.master-serial-info ul li {
  margin-bottom: 5px; /* Spacing between items */
}

.master-serial-input {
  color: blue;
}

.serial-input {
  width: 40%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}