.status-indicator {
  padding: 0.1em 0.1em;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.status-indicator.pass {
  background-color: green;
}

.status-indicator.fail {
  background-color: red;
}

.status-indicator.resolved {
  background-color: blue;
}

.status-indicator.scrap {
  background-color: yellow;
  color: black; /* Better contrast for yellow background */
}